/* eslint-disable no-unused-vars */
<template lang="pug">
	ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
		v-overlay(:value="show" opacity='0.8' z-index='6')
			v-progress-circular(indeterminate color="primary")
		v-card
			v-tabs.filter-tab(v-model='tab' color='#fff' hide-slider)
				v-tab(:ripple='false') {{$t('charts.everyDay')}}
			v-tabs-items(v-model='tab')
				v-tab-item
					v-card.pa-4.pb-0.filter-wrap
						v-row
							v-col.px-3.py-0(cols='12' sm='8' md='6' lg='3')
								DaliyPicker(@emitupdateDate='updateDate')
			v-card(v-resize='onResize').pt-0.pa-4.pb-4.filter-wrap
				v-row.justify-end.exportBtnUpper
					v-col.pa-4(v-if="windowSize.x <= 599")
					v-col.text-right.pa-0(v-if="windowSize.x > 599")
						div(@click="showhelperText")
							ExportBtn(:exportResult='getMemberDepositObj' @emitexportData='exportXlsxFile')
							span(:class="{ show: helperShow }").ml-auto.mr-7.helperText {{$t('common.helpText')}}
				MainSelectItem(:currencyStatus='currencyStatus' @emitDomainValue='updateDomainValue')
				v-row.justify-center
					v-col(cols='12' sm='8' md='6' lg='3')
						v-btn.searchBtn.defult-btn-color(@click='passes(send)' dark rounded style='width:100%; height: 55px;') {{$t('common.search')}}
				v-card.pa-4.filter-wrap.div-w
					v-col.py-0.fixBar.searchDate.justify-center(cols='12' md='6' lg='5' @click='goSearch')
						v-row.py-1
							v-col.ml-5.mr-auto.py-1.px-3(cols="auto")
								span.sub-title  {{searchDates[0]}} ～ {{searchDates[1]}}
							v-col.mr-2.py-1.px-3(cols="auto")
								v-img.researchImg(max-width="25px" :src='imgSrc')
				v-row.justify-center(v-if='basic.items.length !== 0')
							v-col.pa-0(cols='12')
								v-data-table#dataTable.elevation-1.dataTableWrap(:headers='basic.headers' :items='basic.items' :disable-pagination='true' :footer-props="{ disablePagination: true }" :disable-sort='true')
									template(v-slot:body="{ items }")
										tbody
											tr(v-for="(item, index) in items" :key="index")
												td(style="min-width:150px;width:150px;max-width:150px") {{item.date}}
												td.text-right {{item.first}}
												td.text-right {{item.onlyOne}}
												td.text-right {{item.twoToFive}}
												td.text-right {{item.sixToTen}}
												td.text-right {{item.elevenToTwenty}}
												td.text-right {{item.overTwenty}}
</template>

<script>
import _ from 'lodash';
import statusCode from '@/assets/constant/statusCode'; 
import { getDpstCountBucket } from '@/api/General';
import { mapActions, mapGetters } from 'vuex';
import { commaFormatter, toThousandslsFilter } from '@/util/format';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DaliyPicker from '@/components/DatePicker/DaliyPicker';
import ExportBtn from '@/components/ExportBtn';
import MainSelectItem from '@/components/MainSelectItemOCMS';
import { exportXlsx } from '@/util/xlsx';

export default {
	components: {
		ValidationObserver,
		ValidationProvider,
		DaliyPicker,
		ExportBtn,
		MainSelectItem
	},
	data() {
		return {
			dialog: false,
			show: false,
			loading: false,
			windowSize: {
				x: 0,
			},
			currencyStatus: false,
			helperShow: false,
			basic: {
				headers: [
					{
						text: 'Date',
						align: 'start',
						value: 'date',
					},
					{ text: 'First', align: 'right', value: 'first' },
					{ text: 'Only 1', align: 'right', value: 'onlyOne' },
					{ text: '2-5', align: 'right', value: 'twoToFive' },
					{ text: '6-10', align: 'right', value: 'sixToTen' },
					{ text: '11-20', align: 'right', value: 'elevenToTwenty' },
					{ text: '20++', align: 'right', value: 'overTwenty' },		
				],
				items: []
			},
			getMemberDepositObj: {},
			dataItem: null,
			dates: [],
			searchDates: [],
			value: [],
			domainMap: {},
			tab: null,
			imgSrc: require('@/assets/image/research.png'),
		};
	},
	mounted() {
		this.initData();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog']),
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		onResize () {
			this.windowSize = { x: window.innerWidth};
		},
		smoothDown(){
			$('html, body').animate({
				scrollTop: $('.searchBtn').offset().top
			}, 500);
		},
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;
			try {
				let domain = [];
				for (let i of this.value) {
					if (this.domainMap[i].indexOf('-')) {
						let tmpJ = this.domainMap[i].split('-');
						domain.push(tmpJ[0]);
					} else {
						domain.push(this.domainMap[i]);
					}
				}
				const reqData = {
					startDate: this.$moment(this.dates[0]).format('YYYY-MM-DD'),
					endDate: this.$moment(this.dates[1]).format('YYYY-MM-DD'),
					domain
				};
				this.searchDates = [reqData.startDate,reqData.endDate];
				const res = await getDpstCountBucket(reqData);
				if (res.status === statusCode.STATUS_OK) {
					const result = res.result.res;
					this.getMemberDepositObj = JSON.parse(JSON.stringify(result));
					const memberDepositAfterROE = result.ctx_daily;
					memberDepositAfterROE.sort(
						function(a, b) {     						     
							if (a.site_id === b.site_id) {
								return a.dim_ptt > b.dim_ptt ? 1 : -1;
							}					
							return  a.site_id.localeCompare(b.site_id);
						});
					await this.dataTable(memberDepositAfterROE);
					this.smoothDown();
					this.tab = 0;
				} else {
					this.allClean();
					let noMatchData = this.$t('error.noMatchData');
					this.errorDialogStatus(noMatchData);
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				this.allClean();
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		dataTable(memberDeposit) {
			this.dataItem = memberDeposit.length;
			const bucketName = [];
			const bucketId = [];
			const dpstUsers = [];
			const dateTime = [];
			for(let i = 0 ;i < memberDeposit.length; i++){
				bucketName.push(memberDeposit[i].bucket_name);
				bucketId.push(memberDeposit[i].bucket_id);
				dpstUsers.push(memberDeposit[i].dpst_users);
				dateTime.push(memberDeposit[i].dim_ptt);
			}
			
			const firstArr = [];
			const onlyOneArr = [];
			const twoToFiveArr = [];
			const sixToTenArr = [];
			const elevenToTwentyArr = [];
			const overTwentyArr = [];
			const dateArr = [];

			for (let i = 0; i < dateTime.length; i++) {				
				if(bucketId[i] === -1 ){
					firstArr.push(dpstUsers[i]);								
				}else if(bucketId[i] === 0 ){
					onlyOneArr.push(dpstUsers[i]);						
				}else if(bucketId[i] === 1 ){
					twoToFiveArr.push(dpstUsers[i]);	
				}else if(bucketId[i] === 2 ){
					sixToTenArr.push(dpstUsers[i]);	
				}else if(bucketId[i] === 3 ){
					elevenToTwentyArr.push(dpstUsers[i]);	
				}else if(bucketId[i] === 4 ){
					overTwentyArr.push(dpstUsers[i]);	
				}
				if(i % 6 === 0){
					dateArr.push(dateTime[i]);	
				}
			}
			for (let j = 0; j < (this.dataItem/6); j++){
				const basicObj = {};
				basicObj['first'] = toThousandslsFilter(firstArr[j]);
				basicObj['onlyOne'] = toThousandslsFilter(onlyOneArr[j]);
				basicObj['twoToFive'] = toThousandslsFilter(twoToFiveArr[j]);
				basicObj['sixToTen'] = toThousandslsFilter(sixToTenArr[j]);
				basicObj['elevenToTwenty'] = toThousandslsFilter(elevenToTwentyArr[j]);
				basicObj['overTwenty'] = toThousandslsFilter(overTwentyArr[j]);
				basicObj['date'] = dateArr[j];
				this.basic.items.push(basicObj);
			}
		},
		async exportXlsxFile(){
			try {
				let result = this.getMemberDepositObj;
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		allClean() {
			this.basic.items = [];
		},	
		toInt(data) {
			return _.toInteger(data.replace(/,/g, ''));
		},
		centNumToInteger(number) {
			return commaFormatter(number);
		},
		updateDomainValue(val,val2){
			this.value = val;
			this.domainMap = val2;
		},
		updateDate(val) {
			this.dates = val;
		},
		goSearch() {
			$('body,html').animate({scrollTop:0},800);
		},
		showhelperText(){
			if(Object.keys(this.getMemberDepositObj).length=== 0){
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		}
	},
	watch: {
		tab: function() {
			$(function() {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function() {
					let scrollVal = $(this).scrollTop();
					let adscrtop = $('.div-w').offset().top;

					if (scrollVal + 44 > adscrtop) {
						$('.fixBar').addClass('dateFixBar');
					} else {
						$('.fixBar').removeClass('dateFixBar');
					}
				});
			});
		},
		getMemberDepositObj: function() {
			if(Object.keys(this.getMemberDepositObj).length!== 0){
				this.helperShow = false;
			}
		},
	},
	computed: {
		...mapGetters(['getDomain', 'getCurrency', 'getCurrencyList', 'getExchangeRate', 'getDialogShow', 'getStatusDialog']),
	},
	created() {
		this.allClean();
	}
};
</script>
